import * as _dotenv2 from "dotenv";
var _dotenv = "default" in _dotenv2 ? _dotenv2.default : _dotenv2;
import _process from "process";
var exports = {};
var process = _process;
var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
_dotenv.config();
const ENVIRONMENT = (_a = process.env.ENVIRONMENT) !== null && _a !== void 0 ? _a : "PRD";
const TEMP_DATA_DIR_DEV = "./src/.temp";
const TEMP_DATA_DIR_PRD = "./node_modules/node-currency-exchange-rates/dist/.temp";
let TEMP_DATA_DIR = TEMP_DATA_DIR_DEV;
if (ENVIRONMENT === "PRD") {
  TEMP_DATA_DIR = TEMP_DATA_DIR_PRD;
}
exports.default = TEMP_DATA_DIR;
export default exports;